import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationSlo from './assets/locales/slo/translation.json';


i18n

  // to detect user language

  .use(LanguageDetector)




  // to pass the i18n instance to react-i18next.

  .use(initReactI18next)




  // init i18next

.init({
    resources: {
        slo: {
          translation: translationSlo,
        },
        en: {
          translation: translationEN,
        },
      },
    lng: "slo",
    fallbackLng: 'slo',
    

    debug: true,




    interpolation: {

      escapeValue: false, // not needed for react as it escapes by default

    }

  });

export default i18n;