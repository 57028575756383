import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import logo from '../assets/vatovecLogo.webp';
import mlogo from '../assets/icons/mLogo.svg';
import telefon from '../assets/icons/2.svg';
import mail from '../assets/icons/8.svg'
import eng from '../assets/icons/eng.svg';
import slo from '../assets/icons/slo.svg';
import '../styles/Navbar.css';
import i18next from 'i18next'; 
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const pages = ["about", "services", "contact"];
const settings = [
  {text: 'navbar-slo',
    url: "/slovenia",
  },
  {text: 'navbar-tujina',
  url: "/abroad",
  },
  {text: 'navbar-sklad',
  url: "/warehousing",
  },
  {text: 'navbar-other',
  url: "/other",
  }];

function ResponsiveAppBar() {
  const { t, i18n } = useTranslation();
   //const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [small, setSmall] = useState(false);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

   const handleCloseNavMenu = () => {
     //setAnchorElNav(null);
   };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.scrollY > 150)
      );
    }
  }, []);

  return (
    <AppBar position="fixed" className="whiteNav" id={`${
      small ? "smallHeaderFix" : ""}`}>
      <Container maxWidth="100%">
        <Toolbar disableGutters sx={{ flexWrap: 'no-wrap' }} id={`${
          small ? "extraPad" : ""}`}>
        
              <img src={mlogo} className="mobileLogo" id="mobileL" alt="logo" width="55px" height="49px"/>
              <img src={logo} className="mobileLogo" id={`${
      small ? "LFix" : "L"}`} alt="logo" width="273px" height="100px"/>





          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'}}}>
            
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
                
              }}
            >
              
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          {/* <img src={logo} className="logoMobile" alt="logo" /> */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('navbar1')}
          </Typography> */}
          <Box sx={{ flexGrow: 0, marginLeft: 'auto' }}>
          <div className="navbarContactContainer">
            <div className="navbarContact" id="firstNavbarContact">
               <div className="navbarContactImg">
               <img src={telefon} className="navbarCallUsIcon"alt="call now" loading="lazy" width="30px" height="30px"/>
               </div>
               <div className="navbarContactText">
                <h3>{t('navbar2')}</h3>
                <p>{t('navbar3')}</p>

                </div>
            </div>
            <div className="navbarContact">
               <div className="navbarContactImg">
               <img src={mail} className="navbarCallUsIcon"alt="mail now" loading="lazy" width="30px" height="30px"/>
               </div>
               <div className="navbarContactText">
                <h3>{t('navbar4')}</h3>
                <p>{t('navbar5')}</p>

                </div>
            </div>
            <div className="navbarContact" id="burgero">
            <nav role="navigation">
  <div id="menuToggle">

    <input type="checkbox" />
    
    <span></span>
    <span></span>
    <span></span>
    
    <ul id="menu">

    {settings.length>0?
    <>
    {settings.map((setting, index) => (
    <a href={setting.url} key={index}><li>{i18next.t(setting.text)}</li></a>
  ))}
  <li><img src={slo} className="navbarSloIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage('slo')} loading="lazy" width="35px" height="35px"/></li>
  <li><img src={eng} className="navbarSloIcon"alt="English translations" onClick={() => i18n.changeLanguage('en')} loading="lazy" width="35px" height="35px"/></li>
    </>              

  :
  <p>Loading...</p>
}
    </ul>
  </div>
</nav>
            </div>


          </div>
          <div className="navbarSubpageLinkMenu" id={`${
          small ? "small" : ""}`}>
          {pages.map((page) => (
              <a href={"#" + page} key={page} >
               <Button href=""
                 key={page}
                 onClick={handleCloseNavMenu}
                 sx={{ my: 2, color: 'white', display: 'block' }}
                 className="navbarSubpageLinkButton"
               >
                 {t(`navbar-${page}`)}
               </Button>
               </a>
            ))}
    {/* <div className="languages">
<img src={slo} className="navbarSloIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage('slo')}/>

<img src={eng} className="navbarEngUsIcon"alt="English translations" onClick={() => i18n.changeLanguage("en")}/>

        </div> */}
            </div>
          {/* </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } ,justifyContent: 'end' ,width:'100%' }}> */}

          </Box>
          {/*
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } ,justifyContent: 'end', maxWidth:'6%', height: '100%', paddingLeft:'2%', position:'fixed', left:'94%', top: { xs:'92%', md:'12%'}}}>
            <Tooltip title="Open menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, height: { xs: '50px', md:'80px'}, width: { xs:'auto', md:'80px'}}}>
                <Avatar alt="Moja selitev" src={mojaSel} className="avatar"/>
              </IconButton>
            </Tooltip>
            


             <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.length>0?              
              settings.map((setting, index) => (
                <MenuItem key={setting.url} onClick={handleCloseUserMenu} id="menuhh">

                  
                  <Typography textAlign="center"><a href={setting.url}>{i18next.t(setting.text)}</a></Typography>
                </MenuItem>
              ))
              :
              <p>Loading...</p>
            }
            </Menu> 




          </Box>*/}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;