import '../styles/other.css';
import SubpageNavbar from './navbars/SubpageNavbar';
import ExpandableText from './ReadMore';
import * as React from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation} from 'react-i18next';
import { useEffect, useState } from 'react';
import Calculator from './Calculator';
import Footer from './Footer'
function Other() {

    const form4 = React.useRef();
    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("en");
    const [selectedItems, setSelectedItems] = useState(["selitve pisarn"]);

    // Handle selected items from the Calculator component
    // const handleSelectedItemsChange = (items) => {
    //   setSelectedItems(items);
    // };
    const sendEmail = (e) => {
      e.preventDefault();
      let emptyFields = [];
      const inputs = form4.current.querySelectorAll("input[type='text'], textarea");

      inputs.forEach((input, index) => {
          const inputValue = input.value.trim(); // Get and trim value
          console.log(`form current [${index}]`, inputValue); // Log each value
  
          if (inputValue === "") {
              emptyFields.push(input.placeholder || `Field ${index + 1}`); // Store placeholder or generic name
          }
      });

    if (emptyFields.length > 0) {
        alert(`Please fill out the form before submitting`);
        return
    }


      const serializedItems = selectedItems
      .map(item => `${item.name}: ${item.quantity}`)
      .join(', ');
  
    // Create a hidden input to store the serialized selected items
    const selectedItemsInput = document.createElement("input");
    selectedItemsInput.setAttribute("type", "hidden");
    selectedItemsInput.setAttribute("name", "selected_items");
    selectedItemsInput.setAttribute("value", selectedItems[0]);
  
    // Append the hidden input to the form
    form4.current.appendChild(selectedItemsInput);
    
    emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form4.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            alert('message sent successfully...');
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
        form4.current.removeChild(selectedItemsInput);
      };
  return (
    <div className="SloveniaContainer">
    <SubpageNavbar />
    <div className='heroPage'>
        <div className="underHeaderHeroTextOther"><h3>{t('otherHeroText1')}</h3>
        </div>
        <div className="contentContainer" id="otherContentBg">
          <div className="otherContentInnerContainer">
            <div className="firstTextContainers">
        <div className="otherFirstTextContainer">
        <h1>{t('other-title1')}</h1>
        <p>{t('other-subtitle1')}</p><br />
        <p>{t('other-subtitle2')}</p>
        <ul>
        <li>
        {t('other-li1')}<br />
        </li>
        <li>
        {t('other-li2')}<br />
        </li>
        <li>
        {t('other-li3')}<br />
        </li>
        <li>
        {t('other-li4')}<br />
        </li>
        <li>
        {t('other-li5')}<br />
        </li>
        <li>
        {t('other-li6')}<br />
        </li>
        </ul>

        </div>
        <div className="otherSecondTextContainer">
        <h1>{t('other-title2')}</h1>
        <p>{t('other-title2sub1')}</p><br />
        <p>{t('other-title2sub2')}</p>
        <p>{t('other-title2sub3')}</p>

        </div>
        <div className="otherThirdTextContainer">
        <h1>{t('other-title3')}</h1>
        <p>{t('other-title3sub1')}</p><br />
        <p>{t('other-title3sub2')}</p>
        
        </div>
        </div>
        <div className="inquiryPage">
        <div className="inquiryFormContainer">
        <div className="inquiryText">
              <h1>{t('homePovpraševanje1')}</h1>
            </div>
        <div className="inquiryTitle">
          <h3>{t('homePovpraševanje3')}</h3>
        </div>
          <div className="inquiryFormTitle">
            <h2>{t('homeServicesMainTitle2')}</h2>
          </div>
          <div className="inquiryFormInnerContainer">

            <form onSubmit={sendEmail} ref={form4}>
            <span className="lowerHalfOfForm">
              <span className="leftForm">
              <input type="text" id="imePriimek" name="imePriimek" placeholder={t('inquiryGlobal7')} minLength="5"/><br />
              <input type="text" id="email" name="email" placeholder={t('inquiryGlobal8')} minLength="5"/><br />
              <span className="inLineInput">
              <input type="text" id="selimIz" name="selimIz" placeholder={t('inquiryGlobal9')} minLength="5"/>
              <input type="text" id="selimV" name="selimV" placeholder={t('inquiryGlobal10')} minLength="5"/><br />
              </span>
              <span className="selectContainer">
              <span className="selectContainer" id="quickfixCalc">
              {/* <Calculator onSelectedItemsChange={handleSelectedItemsChange}/> */}
              </span>
              </span>
              <input type="text" id="telefon" name="telefon" placeholder={t('inquiryGlobal11')} minLength="5"/><br />
            </span>
            <span className="rightForm">
              <textarea placeholder={t('inquiryGlobal12')} name="povprasevanjeTxt" maxLength='1000' minLength='15' rows="16" cols="89">
                
              </textarea>
            </span>
              
            </span>
            <span className="submits">
            <input type="submit" value={t('inquiryGlobal13')} id="firstSubmit" />
            </span>

            </form>

          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Other;
