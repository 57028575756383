import * as React from 'react';
import '../styles/Footer.css';
import { useTranslation } from 'react-i18next';
import eng from '../assets/icons/eng.svg';
import slo from '../assets/icons/slo.svg';
import { 
  Link
} from "react-router-dom";

function Footer() {
  const { t, i18n } = useTranslation();
  //i18n.changeLanguage('en-US');

  return (
    <section id="footer">
        <div id="footerFirst">
        <p>© 2024 | Vatovec d.o.o.</p>
        <Link to="/responsibility" className="privacyLink">{t('footerCompanyPolicy')}</Link>
        </div>
        <div id="footerSecond">
        <Link to="/privacy" className="privacyLink">{t('footerPrivacyPolicy')}</Link>
        <div className="languages">
           <img src={slo} className="navbarSloIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage('slo')} loading="lazy" width="35px" height="35px"/>
               <img src={eng} className="navbarEngUsIcon"alt="English translations" onClick={() => i18n.changeLanguage("en")} loading="lazy" width="35px" height="35px"/>
          {/* {Object.keys(lngs).map((lng) => (
            <>
               <img src={slo} className="navbarCallUsIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage("Slovenian")}/>
               <img src={eng} className="navbarCallUsIcon"alt="English translations" onClick={() => i18n.changeLanguage("English")}/>
            <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </button>
            </>
          ))} */}
        </div>
        </div>
        <a href="https://bprogramming.com" id="madeBy">
            Made by B.Programming
          </a>
    </section>
  );
}
export default Footer;